import React from "react";
import {
  BellOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  DownOutlined,
  HomeOutlined,
  ProfileOutlined,
  TagOutlined,
  AccountBookOutlined,
  CodeOutlined,
  BankOutlined,
  TeamOutlined,
  CameraOutlined,
  SettingOutlined,
  PictureOutlined,
  StarOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  VideoCameraOutlined,
  GiftOutlined,
  FileTextOutlined,
  ToolOutlined,
  CarOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Badge,
  Dropdown,
  Input,
  Drawer,
  Avatar,
  AutoComplete,
  Button,
  Form,
  notification,
  Space,
} from "antd";
//redux
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { getUserInfo } from "../../utils/redux/reducers/User";
import {
  setUserInfo,
  setUserMerchantStatus,
} from "../../utils/redux/actions/User";
import Base from "../Base";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";
import Home from "../home/Home";
import OrderList from "../order/OrderList";
import RefundList from "../order/RefundList";
import OrderDownload from "../order/OrderDownload";
import ImportTackNumber from "../order/ImportTackNumber";
import DeliverymanManagement from "../deliveryman/DeliverymanManagement";
import CommunityManagement from "../delivery/CommunityManagement";
import ProductList from "../product/ProductList";
import ProductEdit from "../product/ProductEdit";
import AdsManagement from "../sales/ads/AdsManagement";
import CouponsManagement from "../sales/coupons/CouponsManagement";
import NavigatorManagement from "../sales/ads/NavigatorManagement";
import MiniProgramSetting from "../mini/MiniProgramSetting";
import MerchantBasicInfo from "../setting/MerchantBasicInfo";
import ApplymentInfo from "../setting/ApplymentInfo";
import HolidayActivityList from "../sales/activities/HolidayActivityList";
import FlashActivityList from "../sales/activities/FlashActivityList";
import FlashActivityEdit from "../sales/activities/FlashActivityEdit";
import GiftList from "../sales/gift/GiftList";
import ReviewList from "../review/ReviewList";
import FinanceHome from "../finance/FinanceHome";
import FinanceCheck from "../finance/FinanceCheck";
import FinanceCheckDaily from "../finance/FinanceCheckDaily";
import FinanceOrderDetail from "../finance/FinanceOrderDetail";
import FinanceAccount from "../finance/FinanceAccount";
import FinanceBillDetail from "../finance/FinanceBillDetail";
import FinanceDownload from "../finance/FinanceDownload";
import PromotionShop from "../promotion/PromotionShop";
import PromotionShopTicket from "../promotion/PromotionShopTicket";
import PromotionNewUser from "../promotion/PromotionNewUser";
import PromotionDemandReduce from "../promotion/PromotionDemandReduce";
import PromotionShipPrice from "../promotion/PromotionShipPrice";
import ProxyList from "../proxy/ProxyList";
import CommissionList from "../proxy/CommissionList";
import AgentWithdrawList from "../proxy/AgentWithdrawList";
import WithdrawCommissionList from "../proxy/WithdrawCommissionList";
import CommissionWithdrawRequestList from "../proxy/CommissionWithdrawRequestList";
import SendMoneyList from "../proxy/SendMoneyList";
import AgentLevel from "../proxy/AgentLevel";
import ShowsList from "../shows/ShowsList";
import ShowsEdit from "../shows/ShowsEdit";
import SelectLang from "../setting/SelectLang";
import ShopSettingGroup from "../setting/ShopSettingGroup";
import PopupManagement from "../sales/ads/PopupManagement";
import SplashManagement from "../sales/ads/SplashManagement";
import HomeAdManagement from "../sales/ads/HomeAdManagement";
import UserCenterAdManagement from "../sales/ads/UserCenterAdManagement";
import BecomeAgentAdManagement from "../sales/ads/BecomeAgentAdManagement";
import GrouponManagement from "../sales/groupon/GrouponManagement";
import BalanceSpendList from "../balance/BalanceSpendList";
import BalanceReduceList from "../balance/BalanceReduceList";
import BalanceList from "../balance/BalanceList";
import BalanceTopupList from "../balance/BalanceTopupList";
import UsersList from "../users/UsersList";
import LiveList from "../live/LiveList";
import LotteryEdit from "../lottery/LotteryEdit";
import LotteryList from "../lottery/LotteryList";
import LotteryManagers from "../lottery/LotteryManagers";
import LotteryRecord from "../lottery/LotteryRecord";
import LotteryTheme from "../lottery/LotteryTheme";
import LotteryPrize from "../lottery/LotteryPrize";
import LotteryPrePrize from "../lottery/LotteryPrePrize";
import ArticleList from "../article/ArticleList";
import ArticleEdit from "../article/ArticleEdit";
import WeappManagement from "../weapp/WeappManagement";
import EditGroupon from "../sales/groupon/EditGroupon";
import MembersList from "../members/MembersList";
import MembersCard from "../members/MembersCard";
import MembersOrder from "../members/MembersOrder";
import EditMember from "../members/EditMember";
import EditCard from "../members/EditCard";
import RedPacketList from "../red-packet/RedPacketList";
import LotteryThemeEdit from "../lottery/LotteryThemeEdit";
import LotteryQrcodeList from "../lottery-qrcode/qrcodes/QrcodeList";
import LotteryQrcodeActivityList from "../lottery-qrcode/activity/List";
import LotteryStoreList from "../lottery-qrcode/store/StoreList";
import LotteryRecommenderList from "../lottery/recommender/RecommenderList";
import LotteryRecommenderEdit from "../lottery/recommender/RecommenderEdit";
import LotteryAgreementList from "../lottery-qrcode/agreement/AgreementList";
import AgreementEdit from "../lottery-qrcode/agreement/AgreementEdit";
import LotteryRedeemOrderList from "../lottery-qrcode/redeem-order/RedeemOrderList";
import RedeemOrderEdit from "../lottery-qrcode/redeem-order/RedeemOrderEdit";
import LotteryQrcodeActivityEdit from "../lottery-qrcode/activity/EditAct";
import LotteryQrcodeDetailList from "../lottery-qrcode/detail-list/QrcodeDetailList";
import LotteryQrcodeEdit from "../lottery-qrcode/qrcodes/AddOrEditQrcode";

import MediaManagement from "../../components/media/MediaManagement";
import MediaStorageSetting from "../../components/media/MediaStorageSetting";
import CourseTeacherList from "../courses/teachers/TeacherList";
import AddOrEditTeacher from "../courses/teachers/AddOrEditTeacher";
import EditorDemo from "../../components/editor/EditorDemo";

import CourseCategoryList from "../courses/categories/CategoryList";
import ArticleCourseList from "../courses/courses/ArticleCourseList";
import AudioCourseList from "../courses/courses/AudioCourseList";
import VideoCourseList from "../courses/courses/VideoCourseList";
import LinkSelector from "../../components/linkSelector/LinkSelector";
import CourseSliderAdList from "../courses/home-slider/CourseSliderAdList";

import CourseOrderList from "../courses/orders/CourseOrderList";
import QrcodeList from "../lottery-qrcode/qrcodes/QrcodeList";
import StoreEdit from "../lottery-qrcode/store/StoreEdit";
import LotteryRecommenders from "../lottery/LotteryRecommenders";
import RedPacketEdit from "../red-packet/RedPacketEdit";
import RedPacketDetailList from "../red-packet/detail/RedPacketDetailList";
import RedPacketDetailEdit from "../red-packet/detail/RedPacketDetailEdit";
import RedPacketItemsList from "../red-packet/items/RedPacketItemsList";
import ShopList from "../tuangou/shop/ShopList";
import ShopEdit from "../tuangou/shop/ShopEdit";
import FinalShopSliderAdList from "../tuangou/home-slider/ShopSliderAdList";

const SideMenus = [
  {
    key: "/main",
    icon: <HomeOutlined />,
    label: "main.sideMenus.home",
  },
  {
    key: "/main/order",
    icon: <ProfileOutlined />,
    label: "main.sideMenus.order",
    children: [
      {
        key: "/main/order/list",
        icon: null,
        label: "main.sideMenus.orderList",
      },
      {
        key: "/main/order/refund-list",
        icon: null,
        label: "main.sideMenus.refundList",
      },
      {
        key: "/main/order/download",
        icon: null,
        label: "main.sideMenus.downloadList",
      },
      {
        key: "/main/order/import",
        icon: null,
        label: "main.sideMenus.import",
      },
    ],
  },
  {
    key: "/main/delivery",
    icon: <CarOutlined />,
    label: "main.sideMenus.delivery",
    children: [
      {
        key: "/main/delivery/deliveryman",
        icon: null,
        label: "deliveryman.title",
      },
      {
        key: "/main/delivery/community",
        icon: null,
        label: "community.title",
      },
    ],
  },
  {
    key: "/main/product",
    icon: <TagOutlined />,
    label: "main.sideMenus.product",
    children: [
      {
        key: "/main/product/list",
        icon: null,
        label: "main.sideMenus.productList",
        contains: ["/main/product/edit"],
      },
    ],
  },
  {
    key: "/main/manage-ads",
    icon: <StarOutlined />,
    label: "main.sideMenus.manageAds",
    children: [
      {
        key: "/main/manage-ads/ads",
        icon: null,
        label: "main.sideMenus.ads",
      },
      {
        key: "/main/manage-ads/navs",
        icon: null,
        label: "main.sideMenus.navs",
      },
      {
        key: "/main/manage-ads/popups",
        icon: null,
        label: "main.sideMenus.popups",
      },
      {
        key: "/main/manage-ads/splash",
        icon: null,
        label: "main.sideMenus.splash",
      },
      {
        key: "/main/manage-ads/home-ad",
        icon: null,
        label: "main.sideMenus.homeAd",
      },
      {
        key: "/main/manage-ads/user-center-ad",
        icon: null,
        label: "main.sideMenus.userCenterAd",
      },
      {
        key: "/main/manage-ads/become-agent-ad",
        icon: null,
        label: "main.sideMenus.becomeAgentAd",
      },
    ],
  },
  {
    key: "/main/sales",
    icon: <AccountBookOutlined />,
    label: "main.sideMenus.sales",
    children: [
      {
        key: "/main/sales/coupons",
        icon: null,
        label: "main.sideMenus.coupons",
      },
      // {
      //   key: "/main/sales/groupon",
      //   icon: null,
      //   label: "main.sideMenus.groupon",
      // },
      {
        key: "/main/sales/flash",
        icon: null,
        label: "main.sideMenus.flash",
        contains: ["/main/sales/flash-edit"],
      },
      {
        key: "/main/sales/gift",
        icon: null,
        label: "main.sideMenus.gift",
        contains: ["/main/sales/gift"],
      },
    ],
  },
  {
    key: "/main/lottery",
    icon: <GiftOutlined />,
    label: "main.sideMenus.lottery",
    children: [
      {
        key: "/main/lottery/list",
        label: "main.sideMenus.lotteryList",
      },
      {
        key: "/main/lottery/theme",
        label: "main.sideMenus.lotteryTheme",
      },
      {
        key: "/main/lottery/recommender",
        label: "recommender.title",
      },
    ],
  },
  {
    key: "/main/red-packet",
    icon: <ToolOutlined />,
    label: "main.sideMenus.redPacket",
  },
  {
    key: "/main/lottery-qrcode",
    icon: <GiftOutlined />,
    label: "qrcode.title",
    children: [
      {
        key: "/main/lottery-qrcode/list",
        label: "qrcode.listTitle",
      },
      {
        key: "/main/lottery-qrcode/activity",
        label: "qrcode.activity",
      },
      {
        key: "/main/lottery-qrcode/store",
        label: "qrcode.store",
      },
      {
        key: "/main/lottery-qrcode/agreement",
        label: "qrcode.agreement",
      },
      {
        key: "/main/lottery-qrcode/redeem-order",
        label: "qrcode.redeemOrder",
      },
    ],
  },
  {
    key: "/main/article",
    icon: <FileTextOutlined />,
    label: "main.sideMenus.article",
  },
  {
    key: "/main/live",
    icon: <VideoCameraOutlined />,
    label: "main.sideMenus.live",
    children: [
      {
        key: "/main/live/list",
        icon: null,
        label: "main.sideMenus.liveList",
      },
    ],
  },
  {
    key: "/main/mini-program",
    icon: <CodeOutlined />,
    label: "main.sideMenus.mini",
    children: [
      {
        key: "/main/mini-program/setting",
        icon: null,
        label: "main.sideMenus.miniSetting",
      },
    ],
  },
  // {
  //   key: "/main/review",
  //   icon: <EditOutlined />,
  //   label: "main.sideMenus.review",
  //   children: [
  //     {
  //       key: "/main/review/list",
  //       icon: null,
  //       label: "main.sideMenus.reviewList",
  //     },
  //   ],
  // },
  {
    key: "/main/finance",
    icon: <BankOutlined />,
    label: "main.sideMenus.finance",
    children: [
      {
        key: "/main/finance/home",
        icon: null,
        label: "main.sideMenus.financeHome",
      },
      {
        key: "/main/finance/check",
        icon: null,
        label: "main.sideMenus.financeCheck",
        contains: ["/main/finance/check-daily", "/main/finance/order-detail"],
      },
      {
        key: "/main/finance/account",
        icon: null,
        label: "main.sideMenus.financeAccount",
        contains: ["/main/finance/bill-detail"],
      },
      {
        key: "/main/finance/download",
        icon: null,
        label: "main.sideMenus.financeDownload",
      },
      {
        key: "/main/finance/withdraw-list",
        icon: null,
        label: "main.sideMenus.withdrawList",
      },
      {
        key: "/main/finance/send-money-list",
        icon: null,
        label: "main.sideMenus.sendMoney",
      },
      {
        key: "/main/finance/withdraw-request-list",
        icon: null,
        label: "main.sideMenus.withdrawRequestList",
      },
    ],
  },
  {
    key: "/main/members",
    icon: <IdcardOutlined />,
    label: "main.sideMenus.members",
    children: [
      {
        key: "/main/members/list",
        icon: null,
        label: "main.sideMenus.membersList",
      },
      {
        key: "/main/members/card",
        icon: null,
        label: "main.sideMenus.membersCard",
      },
      {
        key: "/main/members/order",
        icon: null,
        label: "main.sideMenus.membersOrder",
      },
    ],
  },
  {
    key: "/main/proxy",
    icon: <TeamOutlined />,
    label: "main.sideMenus.proxy",
    children: [
      {
        key: "/main/proxy/list",
        icon: null,
        label: "main.sideMenus.proxyList",
      },
      {
        key: "/main/proxy/commission-list",
        icon: null,
        label: "main.sideMenus.commissionList",
      },
      {
        key: "/main/proxy/agent-level",
        icon: null,
        label: "main.sideMenus.agentLevel",
      },
    ],
  },
  {
    key: "/main/users",
    icon: <UserOutlined />,
    label: "main.sideMenus.users",
    children: [
      {
        key: "/main/users/list",
        icon: null,
        label: "main.sideMenus.usersList",
      },
    ],
  },
  {
    key: "/main/balance",
    icon: <MoneyCollectOutlined />,
    label: "main.sideMenus.balance",
    children: [
      {
        key: "/main/balance/topup",
        icon: null,
        label: "main.sideMenus.topup",
      },
      {
        key: "/main/balance/spend",
        icon: null,
        label: "main.sideMenus.spend",
      },
      {
        key: "/main/balance/reduce",
        icon: null,
        label: "main.sideMenus.reduce",
      },
      {
        key: "/main/balance/list",
        icon: null,
        label: "main.sideMenus.balanceUserList",
      },
    ],
  },
  {
    key: "/main/shows",
    icon: <CameraOutlined />,
    label: "main.sideMenus.shows",
    children: [
      {
        key: "/main/shows/list",
        label: "main.sideMenus.showsList",
      },
    ],
  },
  {
    key: "/main/weapp-management",
    icon: <ToolOutlined />,
    label: "main.sideMenus.manageWeapp",
  },
  {
    key: "/main/shop",
    icon: <ToolOutlined />,
    label: "tuangou.shop",
    children: [
      {
        key: "/main/shop/list",
        icon: null,
        label: "tuangou.shop",
      },
      {
        key: "/main/shop/slider-ads",
        icon: null,
        label: "tuangou.sliderAd.title",
      },
    ],
  },

  {
    key: "/main/media",
    icon: <PictureOutlined />,
    label: "main.sideMenus.media",
    children: [
      {
        key: "/main/media/files",
        icon: null,
        label: "main.sideMenus.media",
      },

      {
        key: "/main/media/storage-setting",
        icon: null,
        label: "main.sideMenus.storageSetting",
      },
    ],
  },

  {
    key: "/main/course",
    icon: <PictureOutlined />,
    label: "course.otherTitle",
    children: [
      {
        key: "/main/course/teachers",
        icon: null,
        label: "course.teacher.title",
      },
      {
        key: "/main/course/slider-ads",
        icon: null,
        label: "course.sliderAd.title",
      },
      {
        key: "/main/course/categories",
        icon: null,
        label: "course.category.title",
      },
      {
        key: "/main/course/orders",
        icon: null,
        label: "course.order.title",
      },
    ],
  },
  {
    key: "/main/courses",
    icon: <PictureOutlined />,
    label: "course.title",
    children: [
      {
        key: "/main/courses/article-courses",
        icon: null,
        label: "course.titleArticle",
      },
      {
        key: "/main/courses/audio-courses",
        icon: null,
        label: "course.titleAudio",
      },
      {
        key: "/main/courses/video-courses",
        icon: null,
        label: "course.titleVideo",
      },
    ],
  },
  {
    key: "/main/setting",
    icon: <SettingOutlined />,
    label: "main.sideMenus.setting",
    children: [
      {
        key: "/main/setting/shop",
        icon: null,
        label: "main.sideMenus.settingShop",
      },
      {
        key: "/main/setting/lang",
        icon: null,
        label: "main.sideMenus.selectLang",
      },
      {
        key: "/main/setting/merchant-info-setting",
        icon: null,
        label: "main.sideMenus.merchantSetting",
      },
      {
        key: "/main/setting/applyment-info",
        icon: null,
        label: "main.sideMenus.wechatMerchantApplymentInfo",
      },
    ],
  },
];

class Main extends Base {
  /* lifecycle methods */

  constructor(props) {
    super(props);
    this.passForm = React.createRef();
    this.state = {
      ...this.state,
      sideMenus: null,
      sideMenusOpened: null,
      sideMenusSelected: null,
      updatingMerchant: false,
      searchValueDisplay: this.getFromRouterQuery("search") || "",
      drawerVisible: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.getUser()) {
      this.onCheckNewOrder();
      this._initSideMenus();
      this._loadSideMenus();
    } else {
      this.goToLogin();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (prevProps.user !== this.props.user) {
      this._initSideMenus();
      this._loadSideMenus();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.getUser()) {
        this._loadSideMenus();
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  onCheckNewOrder = () => {
    const { user, settings } = this.props;
    if (settings.enable_voice_notification) {
      this.interval = setInterval(async () => {
        try {
          let api = this.api.order.new;
          if (user && user.token) {
            const res = await api.run({ token: user.token });
            if (res.result && res.data.length > 0) {
              const audio = new Audio(res.data);
              audio.play();
              notification.open({
                message: this.i18n.t("order.new"),
                description: this.i18n.t("order.newDesc"),
                onClick: () => {
                  this.props.history.push("/order/list?orderType=waiting");
                },
              });
            }
          }
        } catch (error) {
          console.log(error, "==================>");
        }
      }, 1000 * 300);
    }
  };

  /* render methods */

  render() {
    return (
      <Layout id="route-main">
        {this._renderSider()}
        <Layout id="route-main-layout">
          {this._renderHeader()}
          {this._renderContent()}
        </Layout>
        {this.renderDrawer()}
      </Layout>
    );
  }

  _renderSider() {
    if (!this.getUser()) return null;

    let menus = this.state.sideMenus;
    if (!menus) return null;
    let name = this.i18n.getPropNameByLocale(this.props.user?.merchant, "name");
    if (!this.props.user?.merchant) {
      name = this.isLotteryQrcodeEnabled()
        ? this.i18n.t("common.appNameQrcode")
        : this.i18n.t("common.appName");
    }
    return (
      <Layout.Sider className="main-sider" breakpoint="lg" collapsedWidth="0">
        <div className="logo">
          <Button
            type="link"
            onClick={() => {
              this.props.history.replace("/");
            }}
          >
            <span>{name}</span>
          </Button>
        </div>
        <Menu
          openKeys={this.state.sideMenusOpened}
          selectedKeys={this.state.sideMenusSelected}
          mode="inline"
          theme="dark"
          onOpenChange={this._onSideMenuOpenChage}
          onSelect={this._onSideMenuItemSelect}
          items={menus}
        />
      </Layout.Sider>
    );
  }

  _renderHeader() {
    if (!this.getUser()) return null;

    return (
      <Layout.Header className="main-header">
        {this._renderHeaderMessage()}
        {this._renderHeaderUserDropdown()}
        {this._renderHeaderShopDropdown()}
        {this._renderHeaderStatusDropdown()}
        {this._renderHeaderSearch()}
      </Layout.Header>
    );
  }

  _renderHeaderMessage() {
    const messageCount = this.getUser().message_count || 0;

    return (
      <Button type="link" onClick={this.onHeaderMessageClick}>
        <Badge count={messageCount}>
          <BellOutlined style={{ color: "#fff" }} />
        </Badge>
      </Button>
    );
  }

  _renderHeaderUserDropdown() {
    const placement = this.i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight";

    const menu = {
      items: [
        { key: "reset", label: this.i18n.t("login.reset") },
        { key: "logout", label: this.i18n.t("main.userMenu.logout") },
      ],
      onClick: this.onHeaderUserMenuClick,
    };

    const name = this.getUser()?.nickname;

    return (
      <Dropdown placement={placement} menu={menu}>
        <Button
          type="primary"
          style={{ boxShadow: "none", borderWidth: 0 }}
          className="action-dropdown"
          icon={<DownOutlined />}
        >
          {name}
        </Button>
      </Dropdown>
    );
  }

  _renderHeaderShopDropdown() {
    const merchant = this.getUser()?.merchant;
    const name = this.i18n.getPropNameByLocale(merchant, "name") || "";
    return (
      <Button
        type="primary"
        style={{ boxShadow: "none", borderWidth: 0 }}
        className="action-dropdown"
        onClick={this.onToggleDrawer}
        icon={<DownOutlined />}
      >
        {name}
      </Button>
    );
  }

  _renderHeaderStatusDropdown() {
    const placement = this.i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight";

    let isInBusiness = this.isMerchantInBusinuess();
    const menu = [
      {
        key: "business",
        className: isInBusiness ? "current" : "",
        label: this.i18n.t("main.statusMenu.business"),
        icon: isInBusiness ? <CheckCircleTwoTone /> : null,
      },
      {
        key: "no-business",
        className: !isInBusiness ? "current" : "",
        label: this.i18n.t("main.statusMenu.noBusiness"),
        icon: !isInBusiness ? <CheckCircleTwoTone /> : null,
      },
    ];

    let businessStatus = this.i18n.t("main.statusMenu.statusBusiness");
    if (!isInBusiness) {
      businessStatus = this.i18n.t("main.statusMenu.statusNoBusiness");
    }

    return (
      <Dropdown
        placement={placement}
        menu={{ items: menu, onClick: this.onHeaderBusinessMenuClick }}
      >
        <Button
          style={{ borderWidth: 0, boxShadow: "none" }}
          className="action-dropdown"
          type="primary"
          icon={<DownOutlined />}
        >
          {businessStatus}
        </Button>
      </Dropdown>
    );
  }

  _renderHeaderSearch() {
    let suffix = <span />;
    if (Boolean(this.state.searchValueDisplay)) {
      suffix = <CloseCircleOutlined onClick={this.onHeaderSearchClear} />;
    }

    return (
      <Input
        className="action-search"
        placeholder={this.i18n.t("main.searchHint")}
        value={this.state.searchValueDisplay}
        onChange={this.onHeaderSearchChange}
        suffix={suffix}
        onPressEnter={this.onHeaderSearchConfirm}
      />
    );
  }

  renderShopAutoCompelete() {
    const merchants = this.getUser() && this.getUser().merchants;
    return (
      <AutoComplete
        placeholder={this.i18n.t("login.insertShopName")}
        options={merchants.map((m) => ({
          value: `${m.id}.${this.i18n.getPropNameByLocale(m, "name")}`,
          id: m.id,
        }))}
        filterOption={(inputValue, option) =>
          option.value
            .toLocaleLowerCase()
            .indexOf(inputValue.toLocaleLowerCase()) !== -1
        }
        style={{ width: 180 }}
        onSelect={(value, option) => this.onSelectShop(option.id)}
        defaultOpen
      />
    );
  }

  renderDrawer() {
    const merchants = this.getUser() && this.getUser().merchants;
    const placement = this.i18n.isLocaleRTL() ? "left" : "right";
    const { drawerVisible } = this.state;
    if (merchants && merchants.length > 0) {
      return (
        <Drawer
          title={this.renderShopAutoCompelete()}
          placement={placement}
          open={drawerVisible}
          onClose={() => {
            this.setState({ drawerVisible: false });
          }}
          forceRender={process.env.NODE_ENV === "production"}
        >
          <Menu
            onClick={this.onHeaderShopMenuClick}
            style={{ borderWidth: 0 }}
            items={
              merchants?.length > 0
                ? merchants.map((item) => ({
                    key: item.id,
                    label: (
                      <Space>
                        <Avatar
                          src={item.avatar}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        />
                        {`${item.id}.${this.i18n.getPropNameByLocale(
                          item,
                          "name"
                        )}`}
                      </Space>
                    ),
                  }))
                : []
            }
          />
        </Drawer>
      );
    }
    return null;
  }

  _renderContent() {
    const hasNoPagePermission = this.hasNoPermission();
    if (hasNoPagePermission) {
      return (
        <Layout.Content className="main-content">
          <div className="no-permission">
            <ExclamationCircleOutlined
              style={{ marginTop: 20, fontSize: 20 }}
            />
            <h3>{this.i18n.t("main.noPermission")}</h3>
          </div>
        </Layout.Content>
      );
    }

    const { match } = this.props;

    return (
      <Layout.Content id="main-scroll-component" className="main-content">
        <Switch>
          <Route exact path={`${match.path}`} component={Home} />
          <Route
            path={`${match.path}/order/list`}
            render={(props) => (
              <OrderList {...props} search={this.state.searchValueDisplay} />
            )}
          />
          <Route
            path={`${match.path}/order/refund-list`}
            component={RefundList}
          />
          <Route
            path={`${match.path}/order/download`}
            component={OrderDownload}
          />
          <Route
            path={`${match.path}/order/import`}
            component={ImportTackNumber}
          />
          <Route
            path={`${match.path}/delivery/deliveryman`}
            component={DeliverymanManagement}
          />
          <Route
            path={`${match.path}/delivery/community`}
            component={CommunityManagement}
          />
          <Route path={`${match.path}/product/list`} component={ProductList} />
          <Route path={`${match.path}/product/edit`} component={ProductEdit} />
          <Route
            path={`${match.path}/manage-ads/ads`}
            component={AdsManagement}
          />
          <Route
            path={`${match.path}/manage-ads/navs`}
            component={NavigatorManagement}
          />
          <Route
            path={`${match.path}/manage-ads/popups`}
            component={PopupManagement}
          />
          <Route
            path={`${match.path}/manage-ads/splash`}
            component={SplashManagement}
          />
          <Route
            path={`${match.path}/manage-ads/home-ad`}
            component={HomeAdManagement}
          />
          <Route
            path={`${match.path}/manage-ads/user-center-ad`}
            component={UserCenterAdManagement}
          />
          <Route
            path={`${match.path}/manage-ads/become-agent-ad`}
            component={BecomeAgentAdManagement}
          />
          <Route
            path={`${match.path}/sales/groupon`}
            component={GrouponManagement}
          />
          <Route
            path={`${match.path}/sales/groupon-edit`}
            component={EditGroupon}
          />
          <Route
            path={`${match.path}/sales/coupons`}
            component={CouponsManagement}
          />
          <Route
            path={`${match.path}/sales/holiday`}
            component={HolidayActivityList}
          />
          <Route
            path={`${match.path}/sales/flash`}
            component={FlashActivityList}
          />
          <Route
            path={`${match.path}/sales/flash-edit`}
            component={FlashActivityEdit}
          />
          <Route path={`${match.path}/sales/gift`} component={GiftList} />

          <Route
            path={`${match.path}/lottery-qrcode/list`}
            component={LotteryQrcodeList}
          />
          <Route
            path={`${match.path}/lottery-qrcode/detail-list`}
            component={LotteryQrcodeDetailList}
          />
          <Route
            path={`${match.path}/lottery-qrcode/edit`}
            component={LotteryQrcodeEdit}
          />
          <Route
            exact
            path={`${match.path}/lottery-qrcode/activity`}
            component={LotteryQrcodeActivityList}
          />
          <Route
            exact
            path={`${match.path}/lottery-qrcode/store`}
            component={LotteryStoreList}
          />
          <Route
            exact
            path={`${match.path}/red-packet`}
            component={RedPacketList}
          />
          <Route
            exact
            path={`${match.path}/red-packet/:redPacketId/detail`}
            component={RedPacketDetailList}
          />
          <Route
            exact
            path={`${match.path}/red-packet/:redPacketId/detail/:detailId/items`}
            component={RedPacketItemsList}
          />
          <Route
            exact
            path={`${match.path}/red-packet/edit`}
            component={RedPacketEdit}
          />
          <Route
            exact
            path={`${match.path}/red-packet/edit/:id`}
            component={RedPacketEdit}
          />
          <Route
            exact
            path={`${match.path}/red-packet/:redPacketId/detail/edit`}
            component={RedPacketDetailEdit}
          />
          <Route
            exact
            path={`${match.path}/red-packet/:redPacketId/detail/edit/:id`}
            component={RedPacketDetailEdit}
          />
          <Route
            exact
            path={`${match.path}/lottery/recommender`}
            component={LotteryRecommenderList}
          />
          <Route
            path={`${match.path}/lottery/recommender/:recommenderId`}
            component={LotteryRecommenderEdit}
          />
          <Route
            path={`${match.path}/lottery-qrcode/store/:storeId`}
            component={StoreEdit}
          />

          <Route
            exact
            path={`${match.path}/lottery-qrcode/agreement`}
            component={LotteryAgreementList}
          />
          <Route
            path={`${match.path}/lottery-qrcode/agreement/:agreementId`}
            component={AgreementEdit}
          />
          <Route
            exact
            path={`${match.path}/lottery-qrcode/redeem-order`}
            component={LotteryRedeemOrderList}
          />
          <Route
            path={`${match.path}/lottery-qrcode/redeem-order/:redeemOrderId`}
            component={RedeemOrderEdit}
          />
          <Route
            path={`${match.path}/lottery-qrcode/activity/:actId`}
            component={LotteryQrcodeActivityEdit}
          />

          <Route path={`${match.path}/lottery/list`} component={LotteryList} />
          <Route
            path={`${match.path}/lottery/theme`}
            component={LotteryTheme}
          />
          <Route
            path={`${match.path}/lottery-managers-list`}
            component={LotteryManagers}
          />
          <Route
            path={`${match.path}/lottery-prize-list`}
            component={LotteryPrize}
          />
          <Route
            path={`${match.path}/lottery-pre-list`}
            component={LotteryPrePrize}
          />
          <Route path={`${match.path}/lottery-edit`} component={LotteryEdit} />
          <Route
            path={`${match.path}/lottery-theme-edit`}
            component={LotteryThemeEdit}
          />
          <Route
            path={`${match.path}/lottery-detail`}
            component={LotteryRecord}
          />
          <Route
            path={`${match.path}/lottery-recommenders`}
            component={LotteryRecommenders}
          />

          <Route path={`${match.path}/article`} component={ArticleList} />
          <Route path={`${match.path}/article-edit`} component={ArticleEdit} />
          <Route path={`${match.path}/live/list`} component={LiveList} />
          <Route
            path={`${match.path}/mini-program/setting`}
            component={MiniProgramSetting}
          />
          <Route path={`${match.path}/finance/home`} component={FinanceHome} />
          <Route
            path={`${match.path}/finance/check`}
            component={FinanceCheck}
          />
          <Route
            path={`${match.path}/finance/check-daily`}
            component={FinanceCheckDaily}
          />
          <Route
            path={`${match.path}/finance/order-detail`}
            component={FinanceOrderDetail}
          />
          <Route
            path={`${match.path}/finance/account`}
            component={FinanceAccount}
          />
          <Route
            path={`${match.path}/finance/bill-detail`}
            component={FinanceBillDetail}
          />
          <Route
            path={`${match.path}/finance/withdraw-list`}
            component={AgentWithdrawList}
          />
          <Route
            path={`${match.path}/finance/withdraw-commission-list`}
            component={WithdrawCommissionList}
          />
          <Route
            path={`${match.path}/finance/withdraw-request-list`}
            component={CommissionWithdrawRequestList}
          />
          <Route
            path={`${match.path}/finance/send-money-list`}
            component={SendMoneyList}
          />
          <Route
            path={`${match.path}/finance/download`}
            component={FinanceDownload}
          />
          <Route path={`${match.path}/members/list`} component={MembersList} />
          <Route path={`${match.path}/members/card`} component={MembersCard} />
          <Route
            path={`${match.path}/members/order`}
            component={MembersOrder}
          />
          <Route path={`${match.path}/members/edit`} component={EditMember} />
          <Route path={`${match.path}/members/editCard`} component={EditCard} />
          <Route path={`${match.path}/proxy/list`} component={ProxyList} />
          <Route
            path={`${match.path}/proxy/commission-list`}
            component={CommissionList}
          />
          <Route
            path={`${match.path}/proxy/agent-level`}
            component={AgentLevel}
          />
          <Route path={`${match.path}/users/list`} component={UsersList} />
          <Route
            path={`${match.path}/balance/topup`}
            component={BalanceTopupList}
          />
          <Route
            path={`${match.path}/balance/spend`}
            component={BalanceSpendList}
          />
          <Route
            path={`${match.path}/balance/reduce`}
            component={BalanceReduceList}
          />
          <Route path={`${match.path}/balance/list`} component={BalanceList} />
          <Route path={`${match.path}/shows/edit`} component={ShowsEdit} />
          <Route path={`${match.path}/shows/list`} component={ShowsList} />
          <Route
            path={`${match.path}/media/files`}
            component={MediaManagement}
          />
          <Route
            path={`${match.path}/media/storage-setting`}
            component={MediaStorageSetting}
          />
          <Route
            path={`${match.path}/course/slider-ads`}
            component={CourseSliderAdList}
          />
          <Route
            path={`${match.path}/course/teachers`}
            component={CourseTeacherList}
          />
          <Route
            path={`${match.path}/course/orders`}
            component={CourseOrderList}
          />
          <Route
            path={`${match.path}/course/teacher/edit`}
            component={AddOrEditTeacher}
          />
          <Route
            path={`${match.path}/course/categories`}
            component={CourseCategoryList}
          />
          <Route path={`${match.path}/course/editor`} component={EditorDemo} />
          <Route
            path={`${match.path}/courses/article-courses`}
            component={ArticleCourseList}
          />
          <Route
            path={`${match.path}/courses/audio-courses`}
            component={AudioCourseList}
          />
          <Route
            path={`${match.path}/courses/video-courses`}
            component={VideoCourseList}
          />
          <Route
            path={`${match.path}/setting/shop`}
            component={ShopSettingGroup}
          />
          <Route path={`${match.path}/setting/lang`} component={SelectLang} />
          <Route
            path={`${match.path}/setting/merchant-info-setting`}
            component={MerchantBasicInfo}
          />
          <Route
            path={`${match.path}/setting/applyment-info`}
            component={ApplymentInfo}
          />
          <Route path={`${match.path}/review/list`} component={ReviewList} />
          <Route
            path={`${match.path}/promotion/shop`}
            component={PromotionShop}
          />
          <Route
            path={`${match.path}/promotion/shop-ticket`}
            component={PromotionShopTicket}
          />
          <Route
            path={`${match.path}/promotion/new-user`}
            component={PromotionNewUser}
          />
          <Route
            path={`${match.path}/promotion/demand-reduce`}
            component={PromotionDemandReduce}
          />
          <Route
            path={`${match.path}/promotion/ship-price`}
            component={PromotionShipPrice}
          />
          <Route
            path={`${match.path}/weapp-management`}
            component={WeappManagement}
          />
          <Route path={`${match.path}/shop/edit/:shopId`} component={ShopEdit} />
          <Route path={`${match.path}/shop/list`} component={ShopList} />
          <Route
            path={`${match.path}/shop/slider-ads`}
            component={FinalShopSliderAdList}
          />
          <Route
            path={`${match.path}/link-selector`}
            component={LinkSelector}
          />
        </Switch>
      </Layout.Content>
    );
  }

  /* user methods */

  _onSideMenuOpenChage = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.sideMenusOpened.indexOf(key) === -1
    );
    for (let index = 0; index < this.state.sideMenus.length; index++) {
      const menu = this.state.sideMenus[index];
      if (menu.ley === latestOpenKey) {
        this.setState({
          sideMenusOpened: openKeys,
        });
        return;
      }
    }
    this.setState({
      sideMenusOpened: latestOpenKey ? [latestOpenKey] : [],
    });
  };

  _onSideMenuItemSelect = (item) => {
    this.props.history.replace({ pathname: item.key });
  };

  onHeaderMessageClick = () => {
    this.showModal(this.i18n.t("common.developingFeature"));
  };

  onResetPassword = () => {
    this.passForm.current
      .validateFields()
      .then(async (values) => {
        const token = this.getUserToken();
        if (token) {
          const apiConfig = { token, data: { password: values.password } };
          const apiUrl = this.api.user.resetPassword;
          try {
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              this.showMessage(res.message, Base.Message.success);
              this.hideModal();
            } else {
              this.showMessage(res.message, Base.Message.warning);
            }
          } catch (error) {
            this.handleApiError(apiUrl, error);
          }
        }
      })
      .catch((err) => {
        console.log("Main -> onResetPassword -> err", err);
      });
  };

  onHeaderUserMenuClick = (menu) => {
    if (!menu) return;

    if (menu.key === "logout") {
      this.props.setUserInfo(null);
      this.goToLogin();
    }

    if (menu.key === "reset") {
      const self = this;
      this.showModal(
        this.i18n.t("login.reset"),
        Base.Modal.confirm,
        this.onResetPassword,
        {
          content: (
            <Form ref={this.passForm} labelCol={{ span: 8 }}>
              <Form.Item
                name="password"
                label={this.i18n.t("login.pass")}
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("login.parolHintRequired"),
                  },
                  {
                    min: 6,
                    message: this.i18n.t("login.parolHintMin"),
                  },
                  {
                    max: 16,
                    message: this.i18n.t("login.parolHintMax"),
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label={this.i18n.t("login.passAgain")}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: this.i18n.t("login.parolHintRequired"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(self.i18n.t("login.notMatch"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Form>
          ),
        }
      );
    }
  };

  async onSelectShop(merchantId) {
    try {
      const token = this.getUserToken();
      if (!token) return;

      const api = this.api.user.selectMerchant;
      const apiConfig = {
        token,
        data: {
          merchant_id: merchantId,
        },
      };

      const res = await api.run(apiConfig);
      if (res.result) {
        const { setUserInfo, tokenObject, user } = this.props;
        const merchant = user.merchants.find(
          (item) => item.id === Number(merchantId)
        );
        setUserInfo({ user: { ...user, merchant }, token: tokenObject });
        this.setState({ drawerVisible: false }, () => {
          if (window.location.pathname.indexOf("product/edit") === -1) {
            window.location.reload();
          } else {
            window.location.href = "/main/product/list";
          }
        });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      this.handleApiError(error);
    }
  }

  onHeaderShopMenuClick = (menu) => {
    if (!menu) return;
    this.onSelectShop(menu.key);
  };

  onToggleDrawer = () => {
    this.setState({ drawerVisible: !this.state.drawerVisible });
  };

  onHeaderBusinessMenuClick = (menu) => {
    if (!menu) return;

    let isInBusiness = this.isMerchantInBusinuess();
    if (menu.key === "business") {
      if (isInBusiness) {
        this.showMessage(
          this.i18n.t("main.alreadyInBusiness"),
          Base.Message.info
        );
        return;
      }

      this.showMessage(
        this.i18n.t("main.updatingBusinessStatus"),
        Base.Message.loading
      );
      this.updateMerchantStatus(true);
    } else if (menu.key === "no-business") {
      if (!isInBusiness) {
        this.showMessage(
          this.i18n.t("main.alreadyNoBusiness"),
          Base.Message.info
        );
        return;
      }

      this.showMessage(
        this.i18n.t("main.updatingBusinessStatus"),
        Base.Message.loading
      );
      this.updateMerchantStatus(false);
    }
  };

  onHeaderSearchChange = (e) => {
    let value = e.target.value.trim();

    if (!value.match(/^\d*$/g)) {
      return;
    }

    this.setState({ searchValueDisplay: value });
  };

  onHeaderSearchClear = () => {
    this.setState({ searchValueDisplay: "" }, () => {
      let thisLocation = this.props.location;

      if (this.isLocationOrderList(thisLocation)) {
        this.props.history.replace({
          ...thisLocation,
          pathname: "/main/order/list",
        });
      }
    });
  };

  onHeaderSearchConfirm = (e) => {
    let value = e.target.value;

    if (!Boolean(value)) {
      this.showMessage(this.i18n.t("main.searchHint"), Base.Message.warning);
      return;
    }

    this.setState({ searchValueDisplay: value }, () => {
      this.props.history.replace({
        pathname: "/main/order/list",
      });
    });
  };

  /* server methods */

  async updateMerchantStatus(isInBusiness = false) {
    if (this.state.updatingMerchant) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.user.updateMerchant;
    let apiConfig = {
      token: token,
      data: {
        status: isInBusiness ? 1 : 0,
      },
    };

    this.setState({ updatingMerchant: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("main.updateBusinessStatusSuccess"),
          Base.Message.success
        );
        this.props.setUserMerchantStatus(isInBusiness ? 1 : 0);
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ updatingMerchant: false });
    }
  }

  /* custom methods */

  _initSideMenus() {
    this.sideMenus = SideMenus.map((i) => {
      return {
        ...i,
        label: this.i18n.t(i.label),
        children: i.children?.map((c) => {
          return { ...c, label: this.i18n.t(c.label) };
        }),
      };
    });

    if (!this.isBBShopMerchant() && !this.shopHasAgents()) {
      this.sideMenus = this.sideMenus.filter(
        (item) =>
          ![
            "/main/proxy",
            "/main/balance",
            "/main/shows",
            "/main/live",
          ].includes(item.key)
      );
    }
    if (!this.isCourseEnabled()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => !["/main/course", "/main/courses"].includes(item.key)
      );
    }

    if (!this.isBBShopMerchant() && !this.isThirdPartyShopHasWeapp()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => !["/main/mini-program", "/main/users"].includes(item.key)
      );
      this.sideMenus = this.sideMenus.map((item) => {
        if (item.key === "/main/manage-ads") {
          return {
            ...item,
            children: item.children.filter(
              (i) =>
                i.key !== "/main/manage-ads/home-ad" &&
                i.key !== "/main/manage-ads/splash"
            ),
          };
        }
        return item;
      });
    }

    if (this.isThirdPartyShopHasWeapp() || !this.shopHasAgents()) {
      this.sideMenus = this.sideMenus.map((item) => {
        if (item.key === "/main/manage-ads") {
          return {
            ...item,
            children: item.children.filter(
              (i) => i.key !== "/main/manage-ads/become-agent-ad"
            ),
          };
        }
        return item;
      });
    }

    if (!this.isBBLbsShop()) {
      const arr = [
        "/main/finance/home",
        "/main/finance/check",
        "/main/finance/account",
        "/main/finance/download",
      ];
      this.sideMenus = this.sideMenus.map((item) => {
        if (item.key === "/main/finance") {
          return {
            ...item,
            children: item.children.filter((i) => !arr.includes(i.key)),
          };
        }
        return item;
      });
    }

    if (!this.isBBShopMerchant()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/main/weapp-management"
      );
    }

    if (!this.isBBShopMerchant() && !this.isArticleEnabled()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/main/article"
      );
    }

    if (!this.isBBShopMerchant() && !this.isLotteryEnabled()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/main/lottery"
      );
    }
    if (!this.isLotteryQrcodeEnabled()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/main/lottery-qrcode"
      );
    }

    if (!this.isBBShopMerchant() && !this.isMembersEnabled()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => !item.key.includes("members")
      );
    }
    if (!this.isBBShopMerchant() && !this.isLbsShop()) {
      this.sideMenus = this.sideMenus.filter(
        (item) => item.key !== "/main/delivery"
      );
    }
    if (this.isBBLbsShop()) {
      this.sideMenus = this.sideMenus.map((item) => {
        if (item.key === "/main/finance") {
          return {
            ...item,
            children: item.children.filter(
              (i) =>
                i.key !== "/main/finance/withdraw-list" &&
                i.key !== "/main/finance/withdraw-request-list" &&
                i.key !== "/main/finance/send-money-list"
            ),
          };
        }
        return item;
      });
    }

    if (this.isSoleLotteryWeapp()) {
      this.sideMenus = this.sideMenus.map((item) => {
        if (
          [
            "/main/",
            "/main/order",
            "/main/product",
            "/main/lottery",
            "/main/users",
            "/main/setting",
            "/main/media",
            "/main/course",
            "/main/mini-program",
          ].includes(item.key)
        ) {
          return item;
        }
        return null;
      });
      this.sideMenus = this.sideMenus.filter((i) => i !== null);
    }
  }

  _loadSideMenus() {
    let menus = this.sideMenus;
    let path = this.props.location.pathname;
    let opened = path.slice(path.indexOf("/"), path.lastIndexOf("/"));
    this.setState({
      sideMenus: menus,
      sideMenusOpened: [opened],
      sideMenusSelected: [path],
    });
  }

  isLocationOrderList(location = this.props.location) {
    return location.pathname.indexOf("/order/list") !== -1;
  }

  hasNoPermission() {
    let hasNoPermission =
      this.isRouterProxyRelated() &&
      this.isThirdPartyShop() &&
      !this.shopHasAgents();
    let hasNoWeappPermission =
      this.isRouterWeappRelated() &&
      !this.isBBShopMerchant() &&
      !this.isThirdPartyShopHasWeapp();
    let hasNoLbsPermission = this.isRouterLbsRelated() && !this.isLbsShop();
    let hasNoArticlePermission =
      !this.isBBShopMerchant() &&
      !this.isArticleEnabled() &&
      this.isRouterALRelated();
    let hasNoLotteryPermission =
      !this.isBBShopMerchant() &&
      !this.isLotteryEnabled() &&
      this.isRouterLotteryRelated();
    let hasNoMembersPermission =
      !this.isBBShopMerchant() &&
      !this.isMembersEnabled() &&
      this.isRouterMembersRelated();
    return (
      hasNoPermission ||
      hasNoWeappPermission ||
      hasNoLbsPermission ||
      hasNoLotteryPermission ||
      hasNoMembersPermission ||
      hasNoArticlePermission
    );
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
      tokenObject: state.user.info && state.user.info.token,
      settings: getMerchantSettings(state),
    };
  },
  { setUserInfo, setUserMerchantStatus }
)(Main);
