import { Button, Image, Popconfirm, Space, Tag } from "antd";
import { Locale } from "../../../utils";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
const i18n = Locale.getInstance();

export const shopListColumns = (history, deleteMutation, setLoading, token) => {
  return [
    {
      title: i18n.t("qrcode.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("tuangou.address"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "address")}</span>;
      },
    },
    {
      title: i18n.t("tuangou.location"),
      render: (item) => {
        return <span>{item.latitude}, {item.longitude}</span>;
      },
    },
    {
      title: i18n.t("tuangou.tuanzhang"),
      render: (item) => {
        return (
          <>
            <img
              src={`${item?.user?.avatar}`}
              alt={item?.user?.nickname}
              width={60}
              height={60}
            />
            <span>{item?.user?.nickname}</span>
          </>
        );
      },
    },
    {
      title: i18n.t("common.mobile"),
      render: (item) => {
        return (
          <span>{item?.user?.mobile}</span>
        )}
    },
    
    {
      title: i18n.t("qrcode.regionName"),
      render: (item) => {
        return (
          <div>
            {item?.province ? item.province?.name.zh + " > " : ""}
            {item?.city ? item.city?.name.zh + " > " : ""}
            {item?.region ? item.region?.name.zh : ""}
          </div>
        );
      },
    },
    
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        let color;
        let icon;
        if (item.status === 2) {
          color = "purple";
          icon = <InfoCircleOutlined />;
        } else if (item.status === 0) {
          color = "red";
          icon = <QuestionCircleOutlined />;
        } else {
          color = "green";
          icon = <CheckCircleOutlined />;
        }
        return (
          <>
            <Tag icon={icon} color={color}>
              {item.status_label}
            </Tag>
          </>
        );
      },
    },

    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <Space direction="vertical">
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setLoading();
                deleteMutation.mutate({
                  resource: "tuangou-shops",
                  params: {
                    id: item.id,
                    token,
                  },
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button size="small" icon={<DeleteOutlined />}>
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/shop/edit/${item.id}`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
            
          </Space>
        );
      },
    },
  ];
};
