import React, { useCallback, useRef, useEffect } from "react";
import { Form, Input, Upload, Button, message } from "antd";
import { Locale, ApiManager, Util } from "../../utils";
import { UploadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const util = Util.getInstance();

function PaymentSettingForm({ user }) {
  const [form] = Form.useForm();
  const refData = useRef();

  const getDefaultFile = (fileUrl = "") => {
    if (fileUrl) {
      let file = {
        uid: -1,
        name: `file-${new Date().getTime()}`,
        status: "done",
        url: typeof fileUrl === "boolean" ? "" : fileUrl,
      };

      return [file];
    }
    return [];
  };

  const getData = useCallback(async () => {
    const { token } = user;
    if (token) {
      try {
        const apiUrl = api.weapp.getPayment;
        const apiConfig = {
          token,
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          const {
            wechat_merchant_id,
            certificate_serial_no,
            v3_key,
            wechat_merchant_key,
            public_key_id,
            api_cert,
            api_key,
            public_key,
          } = res.data;
          refData.current = res.data;
          form.setFieldsValue({
            wechat_merchant_id,
            wechat_merchant_key,
            certificate_serial_no,
            public_key_id,
            v3_key,
            public_key: getDefaultFile(public_key),
            api_cert: getDefaultFile(api_cert),
            api_key: getDefaultFile(api_key),
          });
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user, form]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFinish = useCallback(
    async (values) => {
      const { token } = user;
      if (token) {
        const {
          api_cert,
          api_key,
          wechat_merchant_id,
          wechat_merchant_key,
          certificate_serial_no,
          v3_key,
          public_key,
          public_key_id,
        } = values;
        const apiConfig = {
          token,
          data: {
            wechat_merchant_id,
            wechat_merchant_key,
            public_key_id,
            certificate_serial_no,
            v3_key,
          },
        };
        let apiUrl = api.weapp.addPayment;
        if (refData.current && refData.current.id) {
          apiUrl = api.weapp.updatePayment;
          apiConfig.path = { id: refData.current.id };
        }

        if (
          public_key[0] &&
          public_key[0].uid !== -1 &&
          public_key[0].response
        ) {
          apiConfig.data.public_key = public_key[0].response[0].path;
        }
        if (api_cert[0] && api_cert[0].uid !== -1 && api_cert[0].response) {
          apiConfig.data.api_cert = api_cert[0].response[0].path;
        }
        if (api_key[0] && api_key[0].uid !== -1 && api_key[0].response) {
          apiConfig.data.api_key = api_key[0].response[0].path;
        }

        try {
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            message.success(res.message);
            getData();
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [user, getData]
  );

  const onUploadFile = useCallback(
    async ({ file, onError, onSuccess, uploadType }) => {
      if (!file) onError("invalid file");

      const { token } = user;
      if (!token) onError("no user token");

      const uploadFiles = [{ file }];
      const apiUrl = api.weapp.upload;
      const apiConfig = {
        token,
        uploadFiles,
        uploadType,
      };

      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          onSuccess(res.data);
        } else {
          onError(res.message);
        }
      } catch (error) {
        onError(error);
      }
    },
    [user]
  );

  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 8 }}
    >
      <Form.Item name="wechat_merchant_id" label={i18n.t("payment.merchantId")}>
        <Input />
      </Form.Item>
      <Form.Item name="wechat_merchant_key" label={i18n.t("payment.secret")}>
        <Input />
      </Form.Item>
      <Form.Item name="v3_key" label="v3 key">
        <Input />
      </Form.Item>
      <Form.Item name="certificate_serial_no" label="serial no">
        <Input />
      </Form.Item>
      <Form.Item name="public_key_id" label="pub key id">
        <Input />
      </Form.Item>
      <Form.Item
        name="public_key"
        label="Wechat Pub Key"
        valuePropName="fileList"
        getValueFromEvent={(e) => util.normFile(e)}
      >
        <Upload
          customRequest={(e) =>
            onUploadFile({ ...e, uploadType: "public_key" })
          }
        >
          <Button>
            <UploadOutlined /> {i18n.t("sales.upload")}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name="api_cert"
        label="API cert"
        valuePropName="fileList"
        getValueFromEvent={(e) => util.normFile(e)}
      >
        <Upload
          customRequest={(e) => onUploadFile({ ...e, uploadType: "api_cert" })}
        >
          <Button>
            <UploadOutlined /> {i18n.t("sales.upload")}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name="api_key"
        label="API key"
        valuePropName="fileList"
        getValueFromEvent={(e) => util.normFile(e)}
      >
        <Upload
          customRequest={(e) => onUploadFile({ ...e, uploadType: "api_key" })}
        >
          <Button>
            <UploadOutlined /> {i18n.t("sales.upload")}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 4 }}>
        <Button type="primary" htmlType="submit" size="large">
          {i18n.t("sales.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(PaymentSettingForm);
