import { Button } from "antd";
import Input from "antd/lib/input/Input";
import { useHistory } from "react-router-dom";
import { Locale } from "../../../utils";

const i18n = Locale.getInstance();
const ShopListHeader = ({ keyword, setKeyword }) => {
  const history = useHistory();
  return (
    <div className="header">
      <div className="actions">
        <Button
          onClick={() => {
            history.push({
              pathname: "/main/shop/edit/new",
            });
          }}
          type="primary"
        >
          {i18n.t("tuangou.addShop")}
        </Button>
      </div>
      <div className="search">
        <Input
          defaultValue={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          placeholder={i18n.t("qrcode.name")}
        />
        <Button type="primary">{i18n.t("common.search")}</Button>
      </div>
    </div>
  );
};
export default ShopListHeader;
