import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Button, Card, Col, Form, Image, Input, Row, Spin, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";

import { create, getOne, update } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { Locale } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { AMAP_KEY, AMAP_VERSION, AMAP_ZOOM } from "../../../constants";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import UserSelector from "../../../components/main/UserSelector";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ImageUpload from "../../../components/media/ImageUpload";
import TextArea from "antd/es/input/TextArea";

const i18n = Locale.getInstance();
const { Meta } = Card;

function ShopEdit() {
  const { shopId } = useParams();
  const user = useSelector((state) => getUserInfo(state));
  const [storeForm] = Form.useForm();

  let map = useRef(null);
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  let data = false;
  let isLoading = false;
  if (shopId != "new") {
    const result = useQuery(["tuangou-shops", user.token, shopId], () =>
      getOne(
        "tuangou-shops",
        {
          id: shopId,
          token: user.token,
        },
        { enabled: shopId !== "new", retry: 1 }
      )
    );
    data = result.data;
    isLoading = result.isLoading;
  }

  useEffect(() => {
    if (data) {
      storeForm.setFieldsValue({
        user_id: data.user_id,
        ug_name: data.ug_name,
        zh_name: data.zh_name,
        ug_address: data.ug_address,
        zh_address: data.zh_address,
        coordinate: `${data.longitude},${data.latitude}`,
        status: data.status,
        tel: data?.tel,
        shop_info: data?.shop_info,
        images: data?.images_media ?? [],
        logo: data?.logo_media ? [data?.logo_media] : [],
      });
    }
  }, [data]);

  useEffect;

  const updateShopMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("tuangou-shops");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const createShopMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("tuangou-shops");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  const handleSubmit = async (values) => {
    try {
      const { coordinate, ...data } = values;
      console.log("🚀 ~ handleSubmit ~ coordinate:", values);
      if (coordinate && coordinate.indexOf(",")) {
        const latAndLong = coordinate.split(",");
        data["latitude"] = latAndLong[1];
        data["longitude"] = latAndLong[0];
      }
      if (values.images && values.images.length > 0) {
        data["images"] = values.images.map((item) => item["file_path"]);
      }
      if (values.logo && values.logo.length > 0) {
        data["logo"] = values.logo[0]["file_path"];
      }
      console.log("🚀 ~ handleSubmit ~ data:", data);
      if (shopId == "new") {
        createShopMutation.mutate({
          resource: "tuangou-shops",
          params: { data, token: user.token },
        });
      } else {
        updateShopMutation.mutate({
          resource: "tuangou-shops",
          params: { data, token: user.token, id: shopId },
        });
      }
    } catch (error) {
      console.log("🚀 ~ file: ShopEdit.jsx:84 ~ handleSubmit ~ error:", error);
    }
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <>
      <BreadCrumb
        data={[
          {
            href: "/main/shop/list",
            title: i18n.t("tuangou.shop"),
          },
        ]}
      />
      <div className="qrcode">
        <Spin spinning={isLoading}>
          <Form
            form={storeForm}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 10 }}
            onFinish={handleSubmit}
          >
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="user_id"
                  label={i18n.t("member.selectUser")}
                  initialValue={data?.user_id}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <UserSelector
                    propUser={shopId ? data?.user : false}
                    setFieldsValue={setFieldsValue}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="logo"
                  label={i18n.t("financeAccount.logo")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <ImageUpload
                    images={getFieldValue("logo")}
                    setSelectedImages={(image) => {
                      setFieldsValue({
                        logo: image,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="ug_name"
                  label={i18n.t("qrcode.ugName")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("ug_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            zh_name: value,
                          })
                        }
                        from="ug"
                        to="zh"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="zh_name"
                  label={i18n.t("qrcode.zhName")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("zh_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            ug_name: value,
                          })
                        }
                        from="zh"
                        to="ug"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="ug_address"
                  label={i18n.t("community.ugAddress")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("ug_address")}
                        onFinish={(value) =>
                          setFieldsValue({
                            zh_address: value,
                          })
                        }
                        from="ug"
                        to="zh"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="zh_address"
                  label={i18n.t("community.zhAddress")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("zh_address")}
                        onFinish={(value) =>
                          setFieldsValue({
                            ug_address: value,
                          })
                        }
                        from="zh"
                        to="ug"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label={i18n.t("tuangou.coordinate")}
              name="coordinate"
              wrapperCol={{ span: 5 }}
            >
              <Input min={0} />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a target="black" href="https://lbs.amap.com/tools/picker">
                点击获取坐标
              </a>
            </div>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="status"
                  label={i18n.t("common.statusLabel")}
                  shouldUpdate
                  valuePropName="checked"
                  initialValue={getFieldValue("status")}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="images"
                  label={i18n.t("showsEdit.imagesLabel")}
                >
                  <ImageUpload
                    multiple
                    images={getFieldValue("images")}
                    setSelectedImages={(images) => {
                      console.log("🚀 ~ ShopEdit ~ images:", images);
                      setFieldsValue({
                        images: images,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item label={i18n.t("tuangou.info")} name="shop_info">
              <TextArea dir={i18n.isLocaleRTL() ? "rtl" : "ltr"} />
            </Form.Item>

            <Form.Item label={i18n.t("commissionList.tel")} name="tel">
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {i18n.t("productEdit.saveAndBack")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
}

export default ShopEdit;
