import { Button, Drawer, Form, Input, Space, Table } from "antd";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { create, getList, remove } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import { shopListColumns } from "./shopListColumns";
import ShopListHeader from "./ShopListHeader";
import breadCrumbDefault from "./breadCrumb";
import { Locale } from "../../../utils";
import Base from "../../Base";
import TranslateBtn from "../../../components/product/TranslateBtn";

const i18n = Locale.getInstance();
const base = Base.getInstance();
const { TextArea } = Input;

const ShopList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [rejectId, setRejectId] = useState();

  const { data, error, isLoading } = useQuery(
    ["tuangou-shops", [user.token, page, keyword]],
    () =>
      getList("tuangou-shops", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
        },
        token: user.token,
      })
  );

  const updateStoreMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("tuangou-shops");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
    },
    onError: (error) => {
      handleMutationError(error);
    },
    onSettled: () => {
      setRejectId(undefined);
      setLoading(false);
    },
  });

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("tuangou-shops");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  const handleChangePage = (newPage) => {
    console.log(
      "🚀 ~ file: ShopList.jsx:68 ~ handleChangePage ~ newPage:",
      newPage
    );
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  return (
    <>
      <BreadCrumb data={breadCrumbDefault} />
      <div className="qrcode">
        <ShopListHeader keyword={keyword} setKeyword={setKeyword} />
        <Table
          className="list"
          loading={isLoading || loading}
          rowKey="id"
          dataSource={data?.data}
          columns={shopListColumns(
            history,
            deleteMutation,
            setLoading,
            user?.token
          )}
          scroll={{
            x: 1200,
          }}
          pagination={{
            onChange: handleChangePage,
            pageSize: 10,
            total: data?.total,
          }}
        />
      </div>
    </>
  );
};

export default ShopList;
