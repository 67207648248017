import { Button, Form, Input, Radio, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./AddOrEditShopSlider.scss";
import { create, getOne, update } from "../../../utils/http/dataProvider";

import ImageUpload from "../../../components/media/ImageUpload";
import TranslateBtn from "../../../components/product/TranslateBtn";
import LinkSelector from "../../../components/linkSelector/LinkSelector";
import { useHistory, useLocation } from "react-router-dom";

const i18n = Locale.getInstance();
const AddOrEditShopSlider = () => {
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [sliderAdForm] = useForm();
  const history = useHistory();
  const location = useLocation();
  const id = location?.state?.id;

  const { data, isLoading } = useQuery(
    ["slider-ad-detail", user.token],
    () => getOne("ad", { id, token: user.token }),
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (data && id === data?.id) {
      const values = {
        zh_title: data.zh_title,
        ug_title: data.ug_title,
        ug_image: data?.ug_image_media ? [data?.ug_image_media] : [],
        zh_image: data?.zh_image_media ? [data?.zh_image_media] : [],
        order: data.order,
        data: data.link_data,
        status: data.status,
      };

      sliderAdForm.setFieldsValue(values);
    }
    if (!id) {
      sliderAdForm.setFieldsValue({
        zh_title: "",
        ug_title: "",
        ug_image: [],
        zh_image: [],
        order: "",
        data: "",
        status: "",
      });
    }
  }, [data]);

  const addSliderAdMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-slider-ads");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const updateSliderAdMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-slider-ads");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const onFinish = (values) => {
    if (values.ug_image && values.ug_image.length > 0) {
      values["ug_image"] = values["ug_image"][0]["file_path"];
    }
    if (values.zh_image && values.zh_image.length > 0) {
      values["zh_image"] = values["zh_image"][0]["file_path"];
    }
    if (values.data && data?.data != values.data) {
      values["link_data"] = values.data;
      values["data"] = values.data.url;
    }
    values["usage"] = "shop-slider";
    setLoading(true);
    const params = {
      data: values,
      token: user.token,
    };

    if (id) {
      updateSliderAdMutation.mutate({
        resource: "ad",
        params: { ...params, id },
      });
    } else {
      addSliderAdMutation.mutate({ resource: "ad", params });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    showNotificationWithIcon(errorInfo);
  };

  const breadCrumbData = [
    {
      href: "/main/shop/list",
      title: i18n.t("tuangou.shop"),
    },
    {
      href: "/main/shop/slider-ads",
      title: i18n.t("tuangou.sliderAd.title"),
    },
    {
      title: id
        ? i18n.t("course.sliderAd.edit")
        : i18n.t("course.sliderAd.add"),
    },
  ];

  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <Spin spinning={loading || isLoading}>
        <Form
          form={sliderAdForm}
          className="slider-ad-form"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                shouldUpdate
                name="ug_title"
                label={i18n.t("agentLevel.ug")}
                initialValue={data?.ug_title}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_title")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_title: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="zh_title"
                label={i18n.t("agentLevel.ug")}
                initialValue={data?.zh_title}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_title")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_title: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="ug_image"
                label={i18n.t("sales.ad.ugImg")}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <ImageUpload
                  images={getFieldValue("ug_image")}
                  setSelectedImages={(image) => {
                    setFieldsValue({
                      ug_image: image,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="zh_image"
                label={i18n.t("sales.ad.zhImg")}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <ImageUpload
                  images={getFieldValue("zh_image")}
                  setSelectedImages={(image) => {
                    setFieldsValue({
                      zh_image: image,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="data"
                shouldUpdate
                label={i18n.t("sales.ad.type")}
              >
                <LinkSelector
                  selectedLink={data?.link_data ?? false}
                  setSelectedLink={(link) => {
                    setFieldsValue({
                      data: link,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name="status"
            label={i18n.t("course.status")}
            initialValue={1}
          >
            <Radio.Group>
              <Radio defaultChecked={true} value={1}>
                {i18n.t("course.statusActive")}
              </Radio>
              <Radio value={0}>{i18n.t("course.statusInActive")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="order"
            shouldUpdate
            label={i18n.t("shows.sortTypesTitle")}
          >
            <Input style={{ width: 120 }} />
          </Form.Item>

          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              {i18n.t("productEdit.saveAndBack")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddOrEditShopSlider;
